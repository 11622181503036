@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
$primary: #334054;

body {
    font-family: 'Roboto', sans-serif;
    color: $primary;
}

.logo {
    width: 200px;
}

.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 16px;
    line-height: 50px;
    background: $primary;
}

.ant-layout-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    color: #fff;
    background: #334153;
    height: 50px;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: $primary;
}

.site-layout {
    min-height: calc(100vh - 50px - 50px);
    padding: 24px 16px;
    max-width: 900px;
    margin: 50px auto 0;
}

.site-layout .site-layout-background,.ant-layout {
    background: #fff;
}

.item {
    padding-top: 64px;
}

h2 {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 16px;
    align-items: center;
}

.image {
    display: flex;
    max-width: 240px;
    margin: 0 auto 16px;
    object-fit: contain;
}

p {
    font-size: 18px;
    line-height: 21px;
    padding-right: 20%;
    display: flex;
    align-items: center;
}

.num {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $primary;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
}

.content {
    display: flex;

}

[data-rmiz-btn-open], [data-rmiz-btn-close] {
    outline: none;
}

@media (max-width: 767px) {
    .content {
        flex-direction: column;
        p {
            order: 2;
            padding-right: 0;
            align-items: flex-start;
        }
    }
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
    .image {
        display: inline-block;
        max-width: 100%;
        object-fit: initial;
    }
}
